import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import BackgroundImage from "./../assets/images/gucci-1.webp";

export default function LoggedOut() {
  const { login, register } = useKindeAuth();
  return (
    <div
      className="c-body"
      style={{ background: `url(${BackgroundImage}) center no-repeat black` }}
    >
      <header>
        <nav className="nav container">
          <h1 className="c-logo">Goatly</h1>
          <div>
            <button className="btn btn-ghost sign-in-btn" onClick={login}>
              Sign in
            </button>
            <button className="btn btn-dark" onClick={register}>
              Sign up
            </button>
          </div>
        </nav>
      </header>

      <main>
        <div className="container">
          <div className="card hero">
            <p className="text-display-1 hero-title">
              Shhh... <br />
              Goatly is by
              <br />
              invitation
              <br />
              only
            </p>
          </div>
        </div>
      </main>

      <footer className="footer">
        <div className="container">
          <small className="text-subtle">
            © 2022 Goatly, Inc. All rights reserved
          </small>
        </div>
      </footer>
    </div>
  );
}
